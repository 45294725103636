import React from 'react';
import { Heading } from './styles';

export type FormHeadingProps = {
  text: string;
};

const FormHeading: React.FC<FormHeadingProps> = ({ text }) => <Heading>{text}</Heading>;

export default FormHeading;
