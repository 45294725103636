import { graphql, navigate, useStaticQuery } from 'gatsby';
import { useEffect } from 'react';
import { useReplaceDriver } from 'state/forms/replaceDriver/action';
import { usePolicyState } from 'state/policy/state';
import { useUserState } from 'state/user/state';

type AccountPageUrl = {
  csAccountAreaPage: {
    url: string;
  };
  csStaffAreaPage: {
    url: string;
  };
};

export const query = graphql`
  query {
    csAccountAreaPage(page_id: { eq: "account-area-policy" }) {
      url
    }
    csStaffAreaPage(page_id: { eq: "staff-portal" }) {
      url
    }
  }
`;

export const useFormRedirect = (): void => {
  const { csAccountAreaPage, csStaffAreaPage } = useStaticQuery<AccountPageUrl>(query);
  const { policy } = usePolicyState();
  const [replaceDriver] = useReplaceDriver();
  const { isStaffMember } = useUserState();

  useEffect(() => {
    if (!policy || replaceDriver.replacedDriverRef === null) {
      navigate(isStaffMember ? csStaffAreaPage.url : csAccountAreaPage.url);
    }
  }, [policy, csAccountAreaPage.url, replaceDriver, isStaffMember, csStaffAreaPage.url]);
};
