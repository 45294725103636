import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import policyLockClient from 'apiHelpers/policyLockClient';
import { navigate } from 'gatsby';
import { Location } from 'history';
import React from 'react';
import FormFooter from 'components/Forms/FormFooter';
import FormHeading from 'components/Forms/FormHeading';
import FormPageWrapper from 'components/Forms/FormPageWrapper';
import Layout from 'components/Layout';
import { processCtaWithoutLink } from 'helpers/csTypeProcessors';
import { warningWithDetail } from 'helpers/errorReporting';
import { FormPage, getFormPageRoute } from 'helpers/routingHelper';
import { useFormRedirect } from 'helpers/useFormRedirect';
import usePolicyNumber from 'helpers/usePolicyNumber';
import { CsCtaWithoutLink } from 'types/contentStack';
import { DriverType } from 'types/forms';

export type BeforeYouStartData = {
  csAddDriverBeforeYouStartTemplate: {
    meta_title: string;
    heading: string;
    rich_text: string;
    next_button: [CsCtaWithoutLink];
  };
  csAccountAreaPage: {
    url: string;
  };
};

type BeforeYouStartProps = {
  data: BeforeYouStartData;
  location: Location;
  driverType: DriverType;
};

const BeforeYouStartBaseComponent: React.FC<BeforeYouStartProps> = ({
  data,
  location,
  driverType,
}) => {
  const policyPageUrl = data.csAccountAreaPage.url;
  useFormRedirect();
  const page = data.csAddDriverBeforeYouStartTemplate;
  const policyNumber = usePolicyNumber();

  const nextButton = processCtaWithoutLink(page.next_button);

  return (
    <FormPageWrapper
      location={location}
      title={page.meta_title}
      formPageName="beforeYouStart"
      driverType={driverType}>
      <Layout pageType="form" meta={{ title: page.meta_title }}>
        <FormHeading text={page.heading} />
        <RichText html={page.rich_text} />
        <FormFooter
          contentColumns={{ desktop: 9 }}
          nextButton={{
            text: nextButton.displayText,
            screenReaderText: nextButton.screenReaderText,
            onClick: () => {
              policyLockClient.lockPolicy(policyNumber).catch((e) => {
                // If this call errors we don't want to do anything
                // so catching and ignoring this error to prevent it bubbling up.
                warningWithDetail('Failed to lock policy', e.message);
              });
              navigate(getFormPageRoute(driverType, FormPage.ConfirmAddress));
            },
            navOnly: true,
          }}
          backButton={{
            onClick: () => navigate(policyPageUrl),
          }}
        />
      </Layout>
    </FormPageWrapper>
  );
};

export default BeforeYouStartBaseComponent;
