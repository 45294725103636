import FormFooter from '@rsa-digital/evo-shared-components/components/Form/FormFooter';
import { Grid } from '@rsa-digital/evo-shared-components/components/Grid';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const StyledFormFooter = styled(FormFooter)`
  margin-top: ${spacing(4)};

  ${mediaQuery.tabletPortrait`
    margin-top: ${spacing(8)};
  `}

  ${Grid}:first-of-type {
    justify-content: start;
  }
`;
