import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import { fonts } from '@rsa-digital/evo-shared-components/theme/Motability/fonts';
import styled from 'styled-components';

export const Heading = styled.h1`
  ${fonts.headingXLarge}

  margin-top: ${spacing(4)};
  margin-bottom: ${spacing(2)};

  ${mediaQuery.tabletPortrait`
    margin-top: ${spacing(6)};
  `}
`;
