export const formNameStepMapping: { [formType: string]: string } = {
  beforeYouStart: '0',
  confirmAddress: '1',
  driverConsent: '2',
  newDriversCover: '3',
  driverToReplace: '4',
  aboutTheDriver: '5',
  driverDetails: '6',
  licenceDetails: '7',
  claimsHistory: '8',
  drivingOffences: '9',
  licenceRestrictions: '10',
  permanentDriverAgreement: '11',
  temporaryDriverHirerAgreement: '11',
  summary: '12',
  permanentDriverHirerAgreement: '13',
  confirmation: '14',
};
