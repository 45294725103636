import { getDifferenceInMonths } from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import { Location } from 'history';
import React from 'react';
import { useReplaceDriver } from 'state/forms/replaceDriver/action';
import { usePolicyState } from 'state/policy/state';
import ErrorBoundary from 'components/ErrorBoundary';
import ReduxErrorBoundary from 'components/ErrorBoundary/ReduxErrorBoundary';
import SessionExpiryWrapper from 'components/SessionExpiryWrapper';
import { formNameStepMapping } from 'helpers/formNameStepMapping';
import { useAnalyticsFormPageVariables } from 'helpers/useAnalyticsFormPageVariables';
import { hasValue } from 'helpers/valueHelpers';
import { DriverType } from 'types/forms';

const FormPageWrapper: React.FC<{
  location: Location;
  title: string;
  formPageName: keyof typeof formNameStepMapping;
  driverType: DriverType;
}> = ({ location, title, formPageName, driverType, children }) => {
  const { policy } = usePolicyState();
  const [replaceDriverState] = useReplaceDriver();

  useAnalyticsFormPageVariables({
    pageCategories: ['formPage'],
    pageType: 'FormPage',
    userId: policy?.policyDetails.policyNumber ?? undefined,
    policyExpiry: policy?.policyDetails.renewalDate
      ? policy.policyDetails.renewalDate.toUTCString()
      : undefined,
    policyTimeRemaining: policy?.policyDetails.renewalDate
      ? getDifferenceInMonths(new Date(), policy.policyDetails.renewalDate).toString()
      : undefined,
    formName: hasValue(replaceDriverState.replacedDriverRef) ? 'Replace driver' : 'Add driver',
    formPage: title || '',
    formStep: formPageName ? formNameStepMapping[formPageName] : '0',
  });

  return (
    <ErrorBoundary location={location} pageType="form">
      <ReduxErrorBoundary location={location} pageType="form" driverType={driverType}>
        <SessionExpiryWrapper>{children}</SessionExpiryWrapper>
      </ReduxErrorBoundary>
    </ErrorBoundary>
  );
};

export default FormPageWrapper;
