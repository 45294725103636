import { FormFooterProps as BaseFormFooterProps } from '@rsa-digital/evo-shared-components/components/Form/FormFooter';
import { ComponentProps } from '@rsa-digital/evo-shared-components/helpers/componentProps';
import { graphql, useStaticQuery } from 'gatsby';
import React, { MouseEventHandler } from 'react';
import { trackButtonClick } from 'helpers/eventTracking';
import { StyledFormFooter } from './styles';

type FooterButtonsQuery = {
  csMotabilityGlobalConfig: {
    default_form_footer_buttons: {
      back_button_text: string;
      back_button_screen_reader_text: string;
      next_button_text: string;
      next_button_screen_reader_text: string;
    };
  };
};

const footerButtonsQuery = graphql`
  query {
    csMotabilityGlobalConfig {
      default_form_footer_buttons {
        back_button_text
        back_button_screen_reader_text
        next_button_text
        next_button_screen_reader_text
      }
    }
  }
`;

type FormFooterButton = {
  text?: string;
  screenReaderText?: string;
  onClick?: MouseEventHandler;
  disabled?: boolean;
};

type FormFooterProps = {
  backButton: FormFooterButton;
  nextButton?: FormFooterButton & { navOnly?: boolean };
  contentColumns?: BaseFormFooterProps['contentColumns'];
} & ComponentProps;

const FormFooter: React.FC<FormFooterProps> = ({
  backButton,
  nextButton,
  contentColumns,
  ...props
}) => {
  const defaultFooterItems = useStaticQuery<FooterButtonsQuery>(footerButtonsQuery)
    .csMotabilityGlobalConfig.default_form_footer_buttons;

  const finalBackButton: BaseFormFooterProps['backButton'] = {
    buttonText: backButton.text ?? defaultFooterItems.back_button_text,
    screenReaderText:
      backButton.screenReaderText ?? defaultFooterItems.back_button_screen_reader_text,
    onClick: (e) => {
      trackButtonClick('backButton', backButton.text ?? defaultFooterItems.back_button_text)();
      backButton.onClick?.(e);
    },
    disabled: backButton.disabled,
  };

  const finalNextButton: BaseFormFooterProps['nextButton'] = {
    buttonText: nextButton?.text ?? defaultFooterItems.next_button_text,
    screenReaderText:
      nextButton?.screenReaderText ?? defaultFooterItems.next_button_screen_reader_text,
    onClick: (e) => {
      trackButtonClick('nextButton', nextButton?.text ?? defaultFooterItems.next_button_text)();
      nextButton?.onClick?.(e);
    },
    disabled: nextButton?.disabled,
    type: nextButton?.navOnly ? undefined : 'submit',
  };

  return (
    <StyledFormFooter
      contentColumns={contentColumns ?? { desktop: 6 }}
      backButton={finalBackButton}
      nextButton={finalNextButton}
      data-cy="FormNavFooter"
      {...props} // eslint-disable-line react/jsx-props-no-spreading
    />
  );
};

export default FormFooter;
